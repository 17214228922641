









































































import { Component, Vue } from 'vue-property-decorator';
import { api } from '@/api';
import { appName } from '@/env';
import { readLoginError } from '@/store/main/getters';
import { dispatchLogIn } from '@/store/main/actions';

@Component
export default class Login extends Vue {
  public email: string = '';
  public password: string = '';
  public nCliente: string = '';
  public tipo: string = 'inquilino';
  public appName = appName;
  public get loginError() {
    return readLoginError(this.$store);
  }

  public submit() {
    dispatchLogIn(this.$store, {
      username: this.email,
      tipo: this.tipo,
      password: this.password,
      nCliente: this.nCliente
    });
  }
}
